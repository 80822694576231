import React from "react";
import Classes from "../UserTabs/tabs.module.scss";
import { Col, Row } from "antd";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import img from "../../assets/images/section/userImg.svg";
import { useNavigate } from "react-router-dom";
import { CHAIN_CURRENCY } from "../../helpers/common";

const Advance = ({ collectionDetails, collectionNfts, item }) => {
  const navigate = useNavigate();

  const blockchainType = "polygon";
  const minFloorPrice = collectionDetails?.floorPrice?.reduce(
    (min, current) => {
      return min < current.floorPrice ? min : current.floorPrice;
    },
    9999999999999
  );
  const maxTotalVolume =
    collectionDetails?.totalVolume.length &&
    collectionDetails?.totalVolume.reduce((max, { totalVolume }) => {
      return totalVolume > max ? totalVolume : max;
    }, 0);
  const formattedFloorPriceArray = collectionDetails?.floorPrice.map(
    (entry) => {
      const date = new Date(entry._id);
      const month = date.toLocaleString("default", { month: "short" });
      const day = date.getDate();
      return { date: `${month} ${day}`, price: entry.floorPrice };
    }
  );

  const formattedTotalVolumeArray =
    collectionDetails?.totalVolume.length &&
    collectionDetails?.totalVolume.map((entry) => {
      const date = new Date(entry.date);
      const month = date.toLocaleString("default", { month: "short" });
      const day = date.getDate();
      return { date: `${month} ${day}`, volume: entry.totalVolume };
    });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate;
  };
  const soldNFTs = collectionNfts.filter((nft) => nft.isSold);

  const listedNFTs = collectionNfts.filter(
    (nft) => nft.activeAuctions && nft.activeAuctions.length > 0 && !nft.isSold
  );

  const formattedSoldNftsArray = soldNFTs.map(
    ({ updatedAt, activeAuctions }) => {
      let price = 0;
      if (activeAuctions && activeAuctions.length > 0) {
        if (activeAuctions[0]?.sellingMethod === 2) {
          price = activeAuctions[0]?.highestBid || 0;
        } else {
          price = activeAuctions[0]?.sellingConfig?.price?.amount || 0;
        }
      }
      const date = new Date(updatedAt);
      const month = date.toLocaleString("default", { month: "short" });
      const day = date.getDate();
      return {
        date: `${month} ${day}`,
        price: price,
      };
    }
  );

  return (
    <div>
      <Row gutter={[20, 20]} className="mb-5">
        <Col md={8}>
          <div className={`${Classes.topcontainer} px-3`}>
            <span>Volume</span>
            <h2>
              {maxTotalVolume || 0} {CHAIN_CURRENCY[blockchainType]}
            </h2>
            {/* <p>+2197%</p> */}
          </div>
        </Col>
        <Col md={8}>
          <div className={`${Classes.topcontainer} px-3`}>
            <span>Sales</span>
            <h2>{collectionDetails?.soldNfts?.length}</h2>
            {/* <p>+3400%</p> */}
          </div>
        </Col>
        <Col md={8}>
          <div className={`${Classes.topcontainer} px-3`}>
            <span>Floor price</span>
            <h2>
              {minFloorPrice === 9999999999999? '0' : minFloorPrice} {CHAIN_CURRENCY[blockchainType]}
            </h2>
            {/* <p className={Classes.red}>-16%</p> */}
          </div>
        </Col>
      </Row>
      <Row gutter={[20, 20]} className="mb-5">
        {formattedFloorPriceArray.length > 0 && (
          <Col md={12}>
            <div className={`${Classes.topcontainer}`}>
              <h2 className="mb-3 ps-3">Floor Price</h2>
              <ResponsiveContainer
                width="100%"
                height={300}
                style={{ padding: "10px" }}
              >
                <LineChart
                  width={800}
                  height={400}
                  data={formattedFloorPriceArray}
                  margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="price"
                    stroke="#029cf5"
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Col>
        )}
        {collectionDetails?.totalVolume !== 0 && (
          <Col md={12}>
            <div className={`${Classes.topcontainer}`}>
              <h2 className="mb-3 ps-3">Volume</h2>
              <ResponsiveContainer
                width="100%"
                height={300}
                style={{ padding: "10px" }}
              >
                <BarChart
                  width={500}
                  height={300}
                  data={formattedTotalVolumeArray}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Bar
                    dataKey="volume"
                    fill="#0EA5E9"
                    activeBar={<Rectangle fill="#6ecbf7" stroke="#6ecbf7" />}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
        )}
      </Row>
      <Row gutter={[20, 20]} className="mb-5">
        <Col md={12}>
          <Row gutter={[20, 20]}>
            {listedNFTs.length > 0 && (
              <Col md={12}>
                <div className={`${Classes.topcontainer}`}>
                  <h2 className="mb-0 ps-3">Listings</h2>
                  <div className={`${Classes.listingOuter}`}>
                    {listedNFTs.map((nft) => (
                      <div
                        key={nft?._id}
                        className={`${Classes.listing} d-flex justify-content-between align-items-center`}
                      >
                        <div className="d-flex gap-2 align-items-center">
                          {nft?.primaryType === 'video' ? (
                            <video
                              className={Classes.userImg}
                              src={nft?.file}
                              alt="video"
                              width="50"
                              height="50"
                              // controls
                            />
                          ) : (
                            <img
                              className={Classes.userImg}
                              src={nft?.file}
                              alt="img"
                              width="50"
                              height="50"
                            />
                          )}
                          <div>
                            <div className={Classes.title}>{nft?.name}</div>
                            <span className={Classes.time}>
                              {formatDate(nft?.updatedAt)}
                            </span>
                          </div>
                        </div>
                        <button
                          onClick={() => navigate(`/nft/${nft?._id}`)}
                          className={Classes.viewBtn}
                        >
                          View
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            )}
            {/* {soldNFTs.length > 0 && (
              <Col md={12}>
                <div className={`${Classes.topcontainer}`}>
                  <h2 className="mb-0 ps-3">Sales</h2>
                  <div className={`${Classes.listingOuter}`}>
                    {soldNFTs.map((nft) => (
                      <div
                        onClick={() => navigate(`/nft/${nft?._id}`)}
                        key={nft?._id}
                        className={`${Classes.listing} ${Classes.listing2}  d-flex justify-content-between align-items-center`}
                      >
                        <div className="d-flex gap-2 align-items-center">
                          <img
                            className={Classes.userImg}
                            src={nft?.file}
                            alt="img"
                          />
                          <div>
                            <div className={Classes.title}>{nft?.name}</div>
                            <span className={Classes.time}>
                              {formatDate(nft?.updatedAt)}
                            </span>
                          </div>
                        </div>
                        
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            )} */}
          </Row>
        </Col>

        {/* {formattedSoldNftsArray.length > 0 && (
          <Col md={12}>
            <div className={`${Classes.topcontainer}`}>
              <h2 className="mb-3 ps-3">Sales</h2>
              <ResponsiveContainer
                width="100%"
                height={300}
                style={{ padding: "10px" }}
              >
                <LineChart
                  width={800}
                  height={400}
                  data={formattedSoldNftsArray}
                  margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="price"
                    stroke="#029cf5"
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Col>
        )} */}
      </Row>
    </div>
  );
};

export default Advance;
