import React, { useEffect, useState } from "react";
import HeroSection from "../../HeroSection";
import { Col, Row, Select } from "antd";
import { Container } from "react-bootstrap";
import SearchIcon from "../../../assets/images/icons/light-search.svg";
import MainCard from "../../MainCard";
import { getPopularNFTs } from "../../../store/_actions/nft_actions";
import { useSelector, useDispatch } from "react-redux";
import LandingLayout from "../../layout/LandingLayout";
import moment from "moment";
import { Pagination } from 'antd';


const PopularNfts = () => {
  const [limit,setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    causeFilter: "",
    dateFilter: -1,
    search: "",
    page,
    limit
  });
  const dispatch = useDispatch();
  const nfts = useSelector((state) => state?.nft?.popularNfts?.[0]);
  const { categories } = useSelector((state) => state?.categories);
  useEffect(() => {
    dispatch(getPopularNFTs(params));
  }, []);

  useEffect(() => {
    dispatch(getPopularNFTs(params));
  }, [params.causeFilter , params.dateFilter]);
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      dispatch(getPopularNFTs(params));
    }, 700);
    return () => clearTimeout(debounceTimeout);
  }, [params.search]);

  const handleCauseChange = (value) => {
    setParams({ ...params, causeFilter: value });
  };

  const handleDateChange = (value) => {
    setParams({ ...params, dateFilter: value });
  };
  const last7DaysStart = moment().subtract(7, "days").format("YYYY-MM-DD");
  const last15DaysStart = moment().subtract(15, "days").format("YYYY-MM-DD");
  const last30DaysStart = moment().subtract(30, "days").format("YYYY-MM-DD");

  const categoryOptions = categories.map((category) => ({
    value: category._id,
    label: category.categoryName,
  }));

  useEffect(() => {
    setParams((prevParams) => ({
        ...prevParams,
        page: page,
        limit: limit,
    }));
    dispatch(getPopularNFTs({ ...params, page, limit }));
  }, [page, limit]);

  const handlePageClick = (page,pageSize) => {
    setPage(page); 
    setLimit(pageSize);
  };
  return (
    <>
      <LandingLayout>
        <HeroSection />
        <Container>
          <div className="pt-5">
            <h1 className="mb-4 main_heading">Popular NFTs</h1>
            <div
              className={`d-flex align-items-center justify-content-between gap-4 pb-5`}
            >
              <div className={`searchOuter d-flex gap-2`}>
                <img src={SearchIcon} alt="icon" />
                <input
                  className="w-100"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => {
                    setParams({ ...params, search: e.target.value });
                  }}
                />
              </div>
              <div className={`d-flex gap-4`}>
                <Select
                  placeholder="Filter by cause"
                  style={{
                    width: 180,
                  }}
                  onChange={handleCauseChange}
                  options={[
                    {
                      value: "All",
                      label: "All",
                    },
                    ...categoryOptions,
                  ]}
                />
                <Select
                  placeholder="All"
                  style={{
                    width: 180,
                  }}
                  onChange={handleDateChange}
                  options={[
                    {
                      value: "all",
                      label: "All",
                    },
                    {
                      value: last7DaysStart,
                      label: "Last 7 days",
                    },
                    {
                      value: last15DaysStart,
                      label: "Last 15 days",
                    },
                    {
                      value: last30DaysStart,
                      label: "Last 30 days",
                    },
                   
                  ]}
                />
              </div>
            </div>
            <Row gutter={[20, 20]}>
              {nfts && nfts?.data?.length > 0 ? (
                nfts?.data?.map((item, i) => (
                  <Col lg={6} md={12} sm={24} xs={24} key={i}>
                    <MainCard item={item} params={params} setParams={setParams} />
                  </Col>
                ))
              ) : (
                <Col span={24}>
                  <div className="d-flex justify-content-center mt-2">
                    <p>No data found</p>
                  </div>
                </Col>
              )}
            </Row>
            <Pagination
              current={page} // Current page
              pageSize={limit} // Items per page
              total={nfts?.metadata[0]?.total} // Total items
              onChange={handlePageClick} // Handle page change
              showSizeChanger
              pageSizeOptions={['20', '40', '60', '80']} // Updated options
              onShowSizeChange={handlePageClick}
              style={{ textAlign: 'center', margin: '20px 0' }} // Center the pagination
            />
          </div>
        </Container>
      </LandingLayout>
    </>
  );
};

export default PopularNfts;
