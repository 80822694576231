import React, { useEffect, useState } from "react";
import Classes from "./mainCard.module.scss";
import CardImg from "../../assets/images/section/cardImg1.svg";
import { ReactComponent as Heart } from "../../assets/images/icons/heart.svg";
import Polygon from "../../assets/images/icons/polygon.svg";
import { useNavigate } from "react-router-dom";
import { addNftToFav, removeNftToFav } from "../../services/auth.service";
import { alertMessage } from "../../constants/alertMessage";
import { thunkLogin } from "../../store/_actions/user_action";
import { useDispatch, useSelector } from "react-redux";
import { CHAIN_CURRENCY } from "../../helpers/common";
import { VideoHome } from "../../helpers/VideoHome";
import { Tooltip } from "antd";
import CausesList from "../../models/CausesList";
import { FaVideo } from "react-icons/fa";

const MainCard = ({ item, params = () => {}, setParams = () => {}, videoKey }) => {
  const [isActive, setIsActive] = useState(false);
  const [isFav, setIsFav] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { connectedAddress } = useSelector((state) => state.wallet);

  const {
    name,
    nftImage,
    file,
    _id,
    ownerId,
    ownerAddress,
    activeAuctions,
    currentPrice,
    userId,
    blockchainType,
    primaryType,
    nft_categories,
    categoryId,
    nftResaleCount,
    nftDonation,
    role,
  } = item || {};

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const dispatch = useDispatch();
  const authToken = localStorage.getItem("authenticationToken");
  const auth = useSelector((state) => state.auth);

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const checkIsFavourites = async () => {
    const isNftInFavorites = auth?.favourites?.includes(_id);
    setIsFav(isNftInFavorites);
    return Promise.resolve(isNftInFavorites);
  };

  useEffect(() => {
    const updateIsFav = async () => {
      const isFavorite = await checkIsFavourites();
      if (isFav !== isFavorite) {
        setIsFav(isFavorite);
      }
    };

    updateIsFav();
  }, [auth.favourites, _id, isFav]);

  const handleAddToFavourite = async (nftId) => {
    try {
      if (authToken) {
        const data = {
          nftId,
          authToken,
        };
        const response = await addNftToFav(data);

        if (response?.data) {
          checkIsFavourites();
          dispatch(
            thunkLogin(response?.data?.data, () => {
              alertMessage("success", `${response?.data?.message}`);
            })
          );
        }
      }
    } catch (error) {
      console.log("error :", error);
    }
  };

  const handleRemoveToFavourite = async (nftId) => {
    try {
      if (authToken) {
        const data = {
          nftId,
          authToken,
        };
        const response = await removeNftToFav(data);

        if (response?.data) {
          checkIsFavourites();
          dispatch(
            thunkLogin(response?.data?.data, () => {
              alertMessage("success", `${response?.data?.message}`);
            })
          );
        }
      }
    } catch (error) {
      console.log("error :", error);
    }
  };
  const handleCauseChange = (value) => {
    setParams({ ...params, causeFilter: value });
  };
  const handleRedirect = () => {
    // const { id, username } = user;
    navigate(`/nft/${_id}`);
    // navigate(
    //   id && username
    //     ? `/nft/${_id}`
    //     : !id && !username
    //     ? "/login"
    //     : "/get-started"
    // );
  };
  const categories = nft_categories || categoryId;

  const handleSplitName = (name) => {
    const displayText =
      name && name.length > 18 ? name.slice(0, 18) + "..." : name;

    return displayText;
  };

  return (
    <>
      <div className={Classes.mainCard}>
        <div style={{ cursor: "pointer" }}>
          {primaryType === "image" ? (
            <img
              className={Classes.mainImg}
              src={nftImage ? nftImage : file ? file : CardImg}
              alt="img"
              onClick={handleRedirect}
            />
          ) : (
            <div onClick={handleRedirect}>
              <VideoHome key={videoKey} source={file} />
            </div>
          )}

          {authToken ? (
            <div className={Classes.actionBtn}>
              {primaryType === "image" ? (
                ""
              ) : (
                <div
                  className={`${Classes.videoIcon} ${Classes.heart} d-flex align-items-center gap-1`}
                >
                  <FaVideo />
                </div>
              )}
              {isFav ? (
                <div
                  onClick={() => {
                    toggleActive();
                    handleRemoveToFavourite(_id);
                  }}
                  className={Classes.heartActive}
                >
                  <Heart />
                </div>
              ) : (
                <div
                  onClick={() => {
                    toggleActive();
                    handleAddToFavourite(_id);
                  }}
                  className={Classes.heart}
                >
                  <Heart />
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="mt-2">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h3 onClick={handleRedirect} className={Classes.title}>
              {name}
            </h3>
            <img className="align-self-start pt-2" src={Polygon} alt="icon" />
          </div>
          {userId?.firstName && (
            <div
              className="d-flex justify-content-between align-items-center mb-2"
              onClick={() => {
                if (ownerId === user?.id && connectedAddress === ownerAddress) {
                  navigate("/profile");
                } else {
                  navigate(`/user/${userId?.username}`);
                }
              }}
              style={{ cursor: "pointer" }}
            >
              <span className={Classes.desc}>
                {userId?.role === "user"
                  ? "Owned by user"
                  : "Owned by nonprofit"}
              </span>
              <b className={Classes.owner}>
                {ownerId === user?.id && connectedAddress === ownerAddress
                  ? "You"
                  : userId?.role === "user" ? userId?.username: userId?.nonProfitName.length>18?handleSplitName(userId?.nonProfitName):userId?.nonProfitName}
              </b>
            </div>
          )}
        </div>
        {categories && categories?.length > 0 && (
          <div className="d-flex align-items-center justify-content-between gap-2 mb-1">
            <p className={Classes.desc}>Causes</p>
            <div className="d-flex align-items-center gap-1">
              {categories.slice(0, 2).map((category, index) => (
                <Tooltip title={`${category?.categoryName}`}>
                  <button
                    className={Classes.category}
                    onClick={() => {
                      handleCauseChange(category?._id);
                    }}
                  >
                    {" "}
                    {category?.categoryName?.split(" ")[0]}
                  </button>
                </Tooltip>
              ))}
              {categories.length > 2 && (
                <Tooltip title="See more">
                  <div
                    onClick={showModal}
                    className={`${Classes.plusIconCateg} ms-50`}
                  >
                    +
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        )}

        {/* {nftResaleCount && ( */}
        <div className="d-flex align-items-center justify-content-between mb-2">
          <p className={Classes.desc}>NFT Sales</p>
          <b className={Classes.owner}>{nftResaleCount || "N/A"}</b>
        </div>
        {/*  )} */}
        {/* {nftDonation && ( */}
        <div className="d-flex align-items-center justify-content-between mb-2">
          <p className={Classes.desc}>Charity Donations</p>
          <b className={Classes.owner}>
          {nftDonation !== undefined && nftDonation !== null ? `${nftDonation.toFixed(6)} POL` : "N/A"}
          </b>
        </div>
        {/* )} */}
        <div className={`d-flex align-items-center justify-content-between`}>
          <p className={Classes.priceTxt}>Price</p>
          <p className={Classes.price}>
            {currentPrice || activeAuctions?.length ?(activeAuctions[0]?.sellingMethod===1 ? activeAuctions[0]?.sellingConfig?.price?.amount : activeAuctions[0]?.sellingConfig?.startPrice?.amount) : '0' ||"0"}
            {' '+CHAIN_CURRENCY[blockchainType]}
          </p>
        </div>
      </div>
      <CausesList
        showModal={showModal}
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleCauseChange={handleCauseChange}
        nft_categories={categories}
      />
    </>
  );
};

export default MainCard;
