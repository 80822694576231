import React, { useState, Fragment, useEffect, useContext } from "react";
import Classes from "./listingComp.module.scss";
import { Container } from "react-bootstrap";
import { Col, Row, Radio, DatePicker, Modal } from "antd";
import { useNavigate,useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { createAuction } from "../../services/auction.service";
import { useSelector, useDispatch } from "react-redux";
import CONTRACT_DETAILS from "../../contract/contractInfo";
import moment from "moment";
import { useParams } from "react-router-dom";
import { getNFT } from "../../store/_actions/nft_actions";
import SocketContext from "../../context/socket";
import { ThreeDots } from "react-loader-spinner";
import { alertMessage } from "../../constants/alertMessage";
import { fetchOrganizationUsers } from "../../store/_actions/user_action";
import { decimalnumbervalidator, toFixed,CHAIN_ID } from "../../helpers/common";
import Select2 from "react-select";
import Select from "react-select";
import { FaTrash } from "react-icons/fa6";
import useListNftForUser from "../../hooks/useListNFTForUser";
import useListNftForOrganization from "../../hooks/useListNftForOrganization";
import useApprove from "../../hooks/useApproveAmount";
import { ethers } from "ethers";
import  VideoComponent  from "../../helpers/VideoPreview";
import { decodeExample } from "../../helpers/ether";
import { completeTx , saveErrorLog} from "../../services/transaction.service";
import NotificationAlert from "../Alert";
import InProgressAlert from "../InProgressModal";
import CommonModal from "../views/Pages/CommonModal";
import { useNetwork } from 'wagmi';
import SuccessModal from "../CreateNftComp/SuccessModal.jsx";



const ListingComp = () => {
  const [value, setValue] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amountError, setAmountError] = useState("");
  const [auctionStartDate, setAuctionStartDate] = useState("");
  const [auctionEndDate, setAuctionEndDate] = useState("");
  const [datePickerError, setDatePickerError] = useState("");
  const [sellingMethodValue, setSellingMethodValue] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [organizationOptions, setOrganizationOption] = useState([]);
  const [selected, setSelected] = useState({ value: "", input: "" });
  const [selectedOranizations, setSelectedOrganizations] = useState([]);
  const [polygonPrice, setPolygonPrice] = useState("");
  const [usdPrice, setUSDPrice] = useState("");
  const [cryptoAmountError, setCryptoAmountError] = useState("");
  const [fiatAmountError, setFiatAmountError] = useState("");
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [errorMessageTitle, setErrorMessageTitle] = useState("");
  const [platformFeeDefinedByUser, setPlatformFeeDefinedByUser] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsValues, setSelectedOptionsValues] = useState([]);
  const [donationMin, setDonationMin] = useState(5);
  const [donationMax, setDonationMax] = useState(0);
  const [isDonationErrorModalOpen, setIsDonationErrorModalOpen] = useState(false);
  const [donationErrorMessage, setDonationErrorMessage] = useState("You have reached the maximum donations. Thank you for your generosity!");
  const [modalType, setModalType] = useState("Maximum Donations Reached");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isInProgressAlertVisible, setIsInProgressAlertVisible] = useState(false);
  const [isDebugMode, setIsDebugMode] = useState(false);
  const [errLogData, setErrLogData] = useState("");



  const { chain } = useNetwork();
  const chainId = CHAIN_ID[process.env.REACT_APP_CONTRACT_ENV];
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const { nft } = useSelector((state) => state?.nft);
  const auth = useSelector((state) => state.auth);
  const { organizations } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const socket = useContext(SocketContext);
  const { exchangeRate, polyExchangeRate } = useSelector((state) => state.nft);
  const platformSettings = useSelector(
    (state) => state?.platformSettings?.platformSettings
  );
  const { connectedAddress } = useSelector((state) => state.wallet);
  const { categories } = useSelector((state) => state?.categories);

  useEffect(() => {
    dispatch(getNFT(id));
    dispatch(fetchOrganizationUsers());
    setPlatformFeeDefinedByUser(platformFee)
  }, [id]);

  useEffect(() => {
    if(auth?.categories){
      const options = auth?.categories?.map((cat) => {
            return {
              label: cat?.categoryName,
              value: cat?._id,
            };
          });
      handleCategoryChange(options)
    }
  }, [organizations]);

  const showModal = () => {
    let totalDonationPercentage = 0;
    if(selectedOranizations?.length){
       totalDonationPercentage = selectedOranizations.reduce((sum, org) => {
        return sum + (parseFloat(org.percentage) || 0);
      }, 0);
    }
    const maxDonation = 100 - (parseFloat(platformFeeDefinedByUser) + nft?.nft?.royaltyFee/100 + totalDonationPercentage);
    setDonationMax(maxDonation);
    if(maxDonation>5){
      setIsModalOpen(true);
    }
    else{
      showDonationErrorModal()
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setSelected({ value: "", input: "" });
  };
  const showDonationErrorModal = () => {
    setIsDonationErrorModalOpen(true);
  };
  const handleDonationErrorModalCancel = () => {
    setIsDonationErrorModalOpen(false);
  };
  const { RangePicker } = DatePicker;
  const onChangeDate = (value, dateString) => {
    if (
      sellingMethodValue === 2 &&
      dateString[0] === "" &&
      dateString[1] === ""
    ) {
      setDatePickerError("Please select the start and end date for auction");
    } else {
      setDatePickerError("");
      setAuctionStartDate(dateString[0]);
      setAuctionEndDate(dateString[1]);
    }
  };

  const onOk = (value) => {
    if (value[0]) {
      setAuctionStartDate(value[0]);
    }
  };

 

  const onChange = (e,setFieldValue) => {
    setValue(e.target.value);
    setSellingMethodValue(e.target.value);
    setFieldValue('numberOfItems', '');
  };

  const handleChange = () => {
    const alreadySelected = selectedOranizations?.find(
      (item) => item.walletAddress === selected?.value
    );
    if (alreadySelected) {
      alertMessage("error", "This nonprofit is already selected.");
      return;
    }
    let sumOfDonations = 0;
    const donationAmounts = selectedOranizations.map((item) =>
      Number(item.percentage)
    );
    if (donationAmounts && donationAmounts.length > 0) {
      sumOfDonations = donationAmounts.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
    }

    sumOfDonations += parseFloat(platformFeeDefinedByUser);
    sumOfDonations += parseFloat(nft?.nft?.royaltyFee / 100);
    sumOfDonations += parseFloat(selected?.input);

    if (sumOfDonations > 100) {
      alertMessage(
        "error",
        "Please reduce donation percentage. We are making sure that your sum of donations, platform fee & resale royalty must not exceed 100%"
      );
      setIsModalOpen(false);
      return;
    }
    setIsDisabled(true)
    // setSelectedOption(value);
    const selectedOrganization = organizations.find(
      (item) => item?.walletAddress === selected?.value
    );

    let data = [...selectedOranizations];
    data.push({ ...selectedOrganization, percentage: selected.input });
    setSelectedOrganizations(data);
    setSelected({ value: "", input: "" });
    setIsModalOpen(false);
  };
  const {
    listNftUserSentTx,
    listNftForUser, // Renamed the function
    listNftUserTxError,
    listNftUserSentError,
    listNftUserResp,
    isListNftUserSentError,
    isListNftUserError
  } = useListNftForUser();
  const {
    listNftForOrganization,
    listNftOrganizationResp,
    listNftOrganizationSentTx,
    listNftOrganizationTxError,
    listNftOrganizationSentError,
    isListNftOrganizationSentError,
    isListNftOrganizationError
  } = useListNftForOrganization();
  const {
    approve, // Renamed the function
    isApproveCompleted,
    approveMintResp,
    approveTxError,
    isApproveSentError,
    isApproveError
  } = useApprove();
  const {
    approve: approveOrganization, // Renamed the function
    isApproveCompleted: isApproveOrganizationCompleted,
    approveTxError: approveOrganizationTxError,
    approveSentError: approveOrganizationSendError,
  } = useApprove();

  useEffect(() => {
    if (isApproveCompleted) {
      try {
        const percentages = selectedOranizations.map(
          (item) => Math.round(Number(item.percentage) * 100)
        );
        const organizationsAddresses = selectedOranizations.map(
          (item) => item.walletAddress
        );
        const finalPlatformFee = (platformFee && parseFloat(data?.userDefinedPlatformFee) > parseFloat(platformFee))
        ? data?.userDefinedPlatformFee * 100
        : 0;

        listNftForUser({
          args: [
            ethers.utils.parseEther(`${polygonPrice}`),
            sellingMethodValue === 2
              ? moment(auctionStartDate, "YYYY-MM-DD HH:mm").unix()
              : 0,
            sellingMethodValue === 2
              ? moment(auctionEndDate, "YYYY-MM-DD HH:mm").unix()
              : 0,
            nft.nft.tokenId,
            data?.numberOfItems,
            finalPlatformFee,
            CONTRACT_DETAILS.nft.address,
            organizationsAddresses?.length > 0
              ? organizationsAddresses
              : ["0x0000000000000000000000000000000000000000"],
           
            percentages && percentages?.length > 0 ? percentages : [500],
          ],
          from: connectedAddress,
        });
      } catch (err) {
        setIsLoading(false)
        console.log(err, "err");
      }
    }
  }, [isApproveCompleted]);

  useEffect(() => {
    if (listNftUserSentTx?.hash) {
      createANewAuction(listNftUserSentTx?.hash, data);
    }
  }, [listNftUserSentTx?.hash]);
  useEffect(() => {
    if (listNftOrganizationSentTx?.hash) {
      createANewAuction(listNftOrganizationSentTx?.hash, data);
    }
  }, [listNftOrganizationSentTx?.hash]);
  useEffect(() => {
    if (listNftOrganizationResp && listNftOrganizationResp?.logs[2]?.data) {
      handleTxCompeteEvent(listNftOrganizationResp);
    }
  }, [listNftOrganizationResp]);
  useEffect(() => {
    if (listNftUserResp && listNftUserResp?.logs[2]?.data) {
      handleTxCompeteEvent(listNftUserResp);
    }
  }, [listNftUserResp]);
  const handleTxCompeteEvent = (event) => {
    const params = decodeExample(event?.logs[2]?.data);
    const _listId = params?.param1;
    const payload = {
      event: {
        event: "Listed",
        returnValues: {
          _listId,
        },
      },
    };
    const txHash = event?.transactionHash;
    completeTx(txHash, payload);
  };

  useEffect(() => {
    if (isApproveOrganizationCompleted) {
      let fiscalSponser = "0x0000000000000000000000000000000000000000";

      if (auth?.fiscalSponsorId?.walletAddress) {
        fiscalSponser = auth?.fiscalSponsorId?.walletAddress;
      }
      const finalPlatformFee = (platformFee && parseFloat(data?.userDefinedPlatformFee) > parseFloat(platformFee))
        ? data?.userDefinedPlatformFee * 100
        : 0;
     
      listNftForOrganization({
        args: [
          ethers.utils.parseEther(`${polygonPrice}`),
          sellingMethodValue === 2
            ? moment(auctionStartDate, "YYYY-MM-DD HH:mm").unix()
            : 0,
          sellingMethodValue === 2
            ? moment(auctionEndDate, "YYYY-MM-DD HH:mm").unix()
            : 0,
          nft.nft.tokenId,
          data?.numberOfItems,
          CONTRACT_DETAILS.nft.address,
          finalPlatformFee,
          fiscalSponser,
        ],
        from: connectedAddress,
      });
    }
  }, [isApproveOrganizationCompleted]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'D') {
        setIsDebugMode((prev) => !prev);
      }
    };

    if (isAlertVisible) {
      window.addEventListener('keydown', handleKeyPress);
    } else {
      setIsDebugMode(false); // Reset debug mode when modal is not visible
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isAlertVisible]);

  useEffect(() => {
    
    const error =
      approveOrganizationTxError ||
      approveTxError ||
      isApproveError ||
      listNftUserTxError ||
      listNftOrganizationTxError ||
      approveOrganizationSendError ||
      listNftUserSentError ||
      listNftOrganizationSentError;
      
      setErrLogData(error);
     
      if (error?.cause?.name === 'UserRejectedRequestError' || error?.name === 'UserRejectedRequestError') {
        setIsLoading(false);
        setIsInProgressAlertVisible(false)
        setErrorMessageTitle('Transaction Cancelled')
        setShowAlertMessage('User had rejected the transaction.');
        setIsAlertVisible(true);
        return;
      }
      // Check for specific message patterns
      if (error?.message.includes('invalidTimeStamp(uint256 startTime, uint256 endTime)')) {
        setIsLoading(false);
        setIsInProgressAlertVisible(false)
        setErrorMessageTitle('Transaction Cancelled')
        setShowAlertMessage("Transaction denied because start time & end time must be greater than current time.");
        setIsAlertVisible(true);
        return;
      }
      // Handle generic errors
      if(error){
        setIsLoading(false);
        setIsInProgressAlertVisible(false)
        setErrorMessageTitle('Unexpected Error')
        setShowAlertMessage('We are unable to complete your request. Please try again later.');
        setIsAlertVisible(true);
         // New error logging logic
         const percentages = selectedOranizations.map(
          (item) => Math.round(Number(item.percentage) * 100)
        );
        const organizationsAddresses = selectedOranizations.map(
          (item) => item.walletAddress
        );
        const finalPlatformFee = (platformFee && parseFloat(data?.userDefinedPlatformFee) > parseFloat(platformFee))
        ? data?.userDefinedPlatformFee * 100
        : 0;
         const errorLogData = {
          type: 'Listing',
          inputPayload: {
          userId:auth?.id,  
          price:polygonPrice,
          sellingMethodValue,
          auctionStartDate: sellingMethodValue === 2 ? moment(auctionStartDate, "YYYY-MM-DD HH:mm").unix() : 0,
          auctionEndDate: sellingMethodValue === 2 ? moment(auctionEndDate, "YYYY-MM-DD HH:mm").unix() : 0,
          tokenId: nft.nft.tokenId,
          numberOfItems: data?.numberOfItems,
          platformFee: finalPlatformFee,
          nftAddress: CONTRACT_DETAILS.nft.address,
          organizationsAddresses: organizationsAddresses?.length > 0 ? organizationsAddresses : ["0x0000000000000000000000000000000000000000"],
          percentages: percentages && percentages?.length > 0 ? percentages : [500],
          fiscalSponser: auth?.fiscalSponsorId?.walletAddress
          },
          responsePayload: {
            cause: error?.cause,
            message: error?.message,
          },
        };
        saveErrorLog(errorLogData);
      }
     
  }, [
    approveOrganizationTxError,
    approveTxError,
    listNftUserTxError,
    listNftOrganizationTxError,
    listNftUserSentError,
    approveOrganizationSendError,
    listNftOrganizationSentError,
    isApproveSentError,
    isApproveError
  ]);

  useEffect(() => {
    if (isListNftUserSentError||isListNftUserError ||  isApproveSentError ||isApproveError || isListNftOrganizationSentError||
      isListNftOrganizationError) { // removing isApproveSentError ||isApproveError from dependency array & if condition
        const error = isListNftUserSentError||isListNftUserError ||  isApproveSentError ||isApproveError || isListNftOrganizationSentError||
        isListNftOrganizationError;
        setErrLogData(error?.message || JSON.stringify(`TransactionExecutionError: User rejected the request.
          Request Arguments:
            from:  ${connectedAddress}
            to:    ${CONTRACT_DETAILS.market.address}
            data:  0xa44390fe00000000000000000000000033da291fd8a6d253fe8a4db41930f6dbc5a1a40000000000000000000000000000000000000000000000000000000000000000570000000000000000000000000000000000000000000000000000000000000001
          Details: MetaMask Tx Signature: User denied transaction signature.`));
        setIsLoading(false)
      setIsInProgressAlertVisible(false)
      setErrorMessageTitle('Transaction Cancelled')
      setShowAlertMessage('User had rejected the transaction.');
      setIsAlertVisible(true);
      return;
    }
  }, [isListNftUserSentError,isListNftUserError,isApproveSentError,isApproveError, isListNftOrganizationSentError,
    isListNftOrganizationError]);
  useEffect(() => {
    if (Number(polygonPrice) < 0.00001&&polygonPrice?.length>0) {
      setCryptoAmountError("Min 0.00001 amount allowed");
    } else {
      setCryptoAmountError("");
    }
  }, [polygonPrice]);

  const createANewAuction = async (txHash, data) => {
    const { _id } = nft.nft;
    const organizations =
      (selectedOranizations?.length &&
        selectedOranizations.map((item) => ({
          organizationId: item?._id,
          firstName: item?.firstName,
          lastName: item?.lastName,
          nonProfitName: item?.nonProfitName,
          walletAddress: item?.walletAddress,
          percentage: item?.percentage,
        }))) ||
        [];
        console.log("🚀 ~ createANewAuction ~ t:", auctionStartDate)
      console.log("🚀 ~ createANewAuction ~ auctionEndDate:", auctionEndDate)
    const requestData = {
      nftId: _id,
      userId: auth?.id,
      copiesForAuction: data?.numberOfItems,
      initialCopiesForAuction: data?.numberOfItems,
      sellingMethod: sellingMethodValue,
      txHash,
      organizations,
      platformFee: data?.userDefinedPlatformFee * 100,
      sellingConfig: {
        [sellingMethodValue === 1 ? "price" : "startPrice"]: {
          currency: "ETH",
          amount: polygonPrice,
        },
        ...(sellingMethodValue === 2 &&
          auctionStartDate &&
          auctionEndDate && {
            duration: {
              startDate: auctionStartDate,
              endDate: moment(auctionEndDate).toISOString(),
            },
          }),
      },
    };
    const response = await createAuction(requestData);
  };
  const creatingAuctionList = async (data) => {
    setShowAlertMessage("")
    setErrorMessageTitle("")
    const organizationsAddresses = selectedOranizations.map(
      (item) => item.walletAddress
    );
    const percentages = selectedOranizations.map(
      (item) => Number(item.percentage) * 100
    );
    try {
      
      setIsLoading(true);
      if(chainId!==chain?.id){
        setIsLoading(false);
        setErrorMessageTitle('Transaction Cancelled')
        setShowAlertMessage(`Please switch your wallet network to ${chainId===97 ? 'Binance Smart Chain Testnet':'Polygon'}.`);
        setIsAlertVisible(true);
        return;
      }
      if (!data?.amount) {
        setAmountError("Amount is required.");
        setIsLoading(false);
        return;
      }
      
      const marketContractAddress = CONTRACT_DETAILS.market.address;
      setData(data);
      if (auth?.role === "user") {
        if (!organizationsAddresses?.length) {
          alertMessage("error", "Please add nonprofits for donation.");
          setIsLoading(false);
          return;
        }
        setIsInProgressAlertVisible(true);
        approve({
          args: [
            // connectedAddress,
            marketContractAddress,
            nft.nft.tokenId,
            data?.numberOfItems,
          ],
          from: connectedAddress,
        });
      } else {
      setIsInProgressAlertVisible(true);
        approveOrganization({
          args: [
            // connectedAddress,
            marketContractAddress,
            nft.nft.tokenId,
            data?.numberOfItems,
          ],
          from: connectedAddress,
        });
   
      }
      return;
    } catch (error) {
      setIsLoading(false);
      console.log("error :", error);
    }
  };
  useEffect(() => {
    if (socket) {
      const userId = JSON.parse(localStorage.getItem("userInfo"))?._id;
      socket?.emit("join", `${userId}`);
      const handleListing = () => {
        setIsInProgressAlertVisible(false)
        setIsLoading(false);
        navigate(`/nft/${nft?.nft?._id}`, { state: { optionalNftParam: "nftListedSuccessfully" } });
      };
      socket?.on("nftListed", handleListing);
      return () => {
        socket?.off("nftListed", handleListing);
        socket.emit("leave", `${auth?.id}`);
      };
    }
  }, [socket]);

  useEffect(() => {
    // Check if the component was navigated to programmatically
    if (!location.state || !location.state.data || location?.state?.data?.ownerAddress !== auth?.walletAddress) {
      navigate('/'); // Redirect to home or another appropriate page
    }
  }, [location.state, navigate]);

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const disabledStartTime = (current) => {
    const now = moment();
    if (current && current.isSame(now, 'day')) {
      const nextAvailableTime = now.add(5, 'minutes');
      return {
        disabledHours: () =>
          Array.from({ length: 24 }, (_, i) => i).splice(0, nextAvailableTime.hour()),
        disabledMinutes: (selectedHour) => {
          if (selectedHour === nextAvailableTime.hour()) {
            return Array.from({ length: 60 }, (_, i) => i).splice(0, nextAvailableTime.minute());
          }
          return [];
        },
        disabledSeconds: () => [],
      };
    }
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
      disabledSeconds: () => [],
    };
  };

  const disabledEndTime = (current) => {
    if (!current || !auctionStartDate) {
      return {};
    }
    const start = moment(auctionStartDate);
    if (current.isSame(start, 'day')) {
      return {
        disabledHours: () => [...Array(start.hour()).keys()],
        disabledMinutes: (hour) => {
          if (hour === start.hour()) {
            return [...Array(start.minute()).keys()];
          }
          return [];
        },
      };
    }
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
    };
  };

  const { remainingCopies, totalCopiesForSale, primaryType } = nft?.nft;

  const platformFee =
    (platformSettings && platformSettings[0]?.platformFee) || 2.5;
  const handleOrganizationSelect = (e) => {
    setSelected({ ...selected, value: e?.value });
  };
  const handleDeleteOrganization = async (index) => {
    const updatedOrganizations = [...selectedOranizations];
    // Remove the organization at the specified index
    updatedOrganizations.splice(index, 1);
    // Update the state with the new array without the deleted organization
    setSelectedOrganizations(updatedOrganizations);
    setSelected({ value: "", input: "" });
    if(updatedOrganizations?.length===0){
      setIsDisabled(false)
    }
  };

  const handleCategoryChange = (selectedOptions) => {
    const selectedCategoryIds = selectedOptions?.map(option => option?.value);
  
    // Set selected options and category values
    setSelectedOptionsValues(selectedCategoryIds);
    setSelectedOptions(selectedOptions);
  
    // If there are selected options, filter organizations by matching categories
    if (selectedCategoryIds.length > 0) {
      const filteredOrganizations = organizations&&organizations.filter(organization =>
        organization?.categories?.some(category =>
          selectedCategoryIds.includes(category)
        )
      );

      const options = filteredOrganizations && filteredOrganizations?.map((organization) => {
        return {
          label: organization?.nonProfitName,
          value: organization?.walletAddress,
        };
      });
      
      if(options?.length){
        setOrganizationOption(options);
      }else{
        setOrganizationOption([])
      }
    }else{
      const options = organizations && organizations?.map((organization) => {
        return {
          label: organization?.nonProfitName,
          value: organization?.walletAddress,
        };
      });
      setOrganizationOption(options);
    } 
  };
  
  const categoriesOptions =
  categories?.map((category) => ({
    label: category?.categoryName,
    value: category?._id,
  })) || [];

  return (
    <div className={Classes.listing}>
      <Container>
        <h1 className={Classes.title}>List for sale</h1>
        <Row gutter={[20, 20]}>
          <Col lg={7} md={24} sm={24} xs={24}>
            <h3 className={Classes.subtitle}>Preview Item</h3>
            <p className={Classes.desc}>Your NFT will look like this </p>
            <div className={Classes.nftCard}>
              <div>
                <div className={Classes.previewimg}>
                  {primaryType === "image" ? (
                    <img
                      className={Classes.varified}
                      src={nft?.nft?.file}
                      alt="img"
                    />
                  ) : (
                    <VideoComponent source={nft?.nft?.file} />
                  )}
                </div>
              </div>

              <div className={Classes.cardInfo}>
                <div
                  className={`d-flex justify-content-between align-items-center mb-2`}
                >
                  <p className={Classes.price}>
                    {nft?.nft?.name ? (
                      nft?.nft?.name
                    ) : (
                      <span className={Classes.previewTxt}></span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={17}>
            <div className="">
             

              <Formik
                initialValues={{
                  amount: "",
                  numberOfItems: "",
                  amountUsd: "",
                  userDefinedPlatformFee: platformFee
                }}
                validationSchema={Yup.object().shape({
                  numberOfItems: Yup.number()
                    .min(1, "Number of items must be at least 1.")
                    .max(
                      sellingMethodValue === 1 ? Number(remainingCopies - totalCopiesForSale) : 1,
                      ({ max }) => `Number of items should not exceed ${max}.`
                    )
                    .required("Number of items is required."),
                  userDefinedPlatformFee: Yup.number()
                    .min(platformFee, ({ min }) => `Platform fee must be greater than ${platformFee} and less than 30.`)
                    .max(30, ({ max }) => `Platform fee must be greater than ${platformFee} and less than 30.`)
                    .required('Platform fee is required.'),
                })}
                onSubmit={(values, { resetForm }) => {
                  const data = {
                    numberOfItems: values.numberOfItems,
                    amount:
                      values.amount ||
                      (values?.amountUsd &&
                        (polyExchangeRate * values?.amountUsd)?.toFixed(2)),
                    platformFee: platformFee,
                    userDefinedPlatformFee: values?.userDefinedPlatformFee
                  };
                 
                  creatingAuctionList(data);
                  //resetForm({ values: '' });
                }}
                textAlign="center"
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                  isValid,
                  dirty
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="mt-8 space-y-6"
                    noValidate
                    autoComplete="off"
                  >
                     <>
                <p className={Classes.sideTitle}>Listing Method*</p>
                <div className={Classes.radioGroup}>
                  <Radio.Group
                    className="w-100"
                    onChange={(e) => onChange(e, setFieldValue)}
                    value={value}
                  >
                    <div
                      className={`${Classes.border_bottom} p-2 position-relative w-100`}
                    >
                      <Radio className="w-100" value={1}>
                        <p className={`${Classes.boldtxt} mb-1`}>Fixed price</p>
                        <p className={Classes.description}>
                          The item is listed at the price you set.
                        </p>
                      </Radio>
                    </div>
                    <div className="p-2 position-relative w-100">
                      <Radio className="w-100" value={2}>
                        <p className={`${Classes.boldtxt} mb-1`}>
                          Sell to highest bidder
                        </p>
                        <p className={Classes.description}>
                          The item is listed for auction.
                        </p>
                      </Radio>
                    </div>
                  </Radio.Group>
                </div>
              </>
                    <p className={Classes.sideTitle}># of items*</p>
                    <div className={Classes.formInput}>
                      <input
                        className="mb-2"
                        placeholder="Number of Items"
                        type="number"
                        name="numberOfItems"
                        min={1}
                        max={sellingMethodValue===1 ? Number(remainingCopies - totalCopiesForSale):1}
                        onKeyDown={(e) => decimalnumbervalidator(e)}
                        onBlur={handleBlur}
                        onFocus={(e) => e.preventDefault()}
                        onChange={handleChange}
                        value={values.numberOfItems}
                        onWheel={(event) => {
                          event.preventDefault();
                        }}
                      />
                      {sellingMethodValue===1 && <p className={Classes.subinfo}>
                         {Number(remainingCopies - totalCopiesForSale)}{" "}
                        available
                      </p>}
                      {sellingMethodValue===2 && <p className={Classes.subinfo}>
                         {Number(remainingCopies - totalCopiesForSale)}{" "}
                        available but you can list 1 at a time.
                      </p>}
                      <span
                        className="mt-1 d-block"
                        size={"small"}
                        style={{ color: "red" }}
                      >
                        {errors.numberOfItems && touched.numberOfItems ? (
                          <Fragment>{errors.numberOfItems}</Fragment>
                        ) : null}
                      </span>
                    </div>
                    <p className={Classes.sideTitle}>Platform Fee*</p>
                    <div className={Classes.formInput}>
                      <input
                        className="pe-3"
                        type="text"
                        name="userDefinedPlatformFee"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          setPlatformFeeDefinedByUser(e.target.value);
                        }}
                        onKeyDown={(e) =>decimalnumbervalidator(e,true,2)}
                        value={values?.userDefinedPlatformFee}
                        placeholder="e.g 3"
                        disabled={isDisabled}
                      />
                  
                      <span
                        className="mt-1 d-block"
                        size={"small"}
                        style={{ color: "red" }}
                      >
                        {errors.userDefinedPlatformFee && touched.userDefinedPlatformFee ? (
                          <Fragment>{errors.userDefinedPlatformFee}</Fragment>
                        ) : null}
                      </span>
                    </div>
                    <>
                      <h3 className={Classes.metaTitle}>
                        Set a price per item*
                      </h3>
                      <div className={Classes.formInput2}>
                        <div
                          className="mb-4"
                          style={{ display: "flex", gap: "20px" }}
                        >
                          <div className="mb-4" style={{ flex: 1 }}>
                            <p className={Classes.label}>Polygon price</p>
                            <div className={Classes.outer}>
                              <input
                                placeholder="Amount"
                                type="number"
                                name="amount"
                                onChange={(e) => {
                                  const value = Number(e.target.value);
                                  if (value < 0) {
                                    return;
                                  }

                                  handleChange(e);

                                  setUSDPrice(
                                    e.target.value?.length>0?  toFixed(exchangeRate * value,5):exchangeRate * value
                                  );
                                  setPolygonPrice(e.target.value);
                                }}
                                onBlur={handleBlur}
                                onFocus={(e) => e.preventDefault()}
                                // onInput={handleWheel}
                                onKeyDown={(e) => decimalnumbervalidator(e,true)}
                                onWheel={(event) => {
                                  event.preventDefault();
                                }}
                                value={polygonPrice?.toString()}
                                style={{
                                  width: "100%",
                                  WebkitAppearance: "none",
                                  MozAppearance: "textfield",
                                }} // Disable spin buttons
                              />
                              <span>POL</span>
                              {/* {values?.amount && (
                              <span style={{ marginLeft: "5px" }}>
                                ${(exchangeRate * values?.amount)?.toFixed(2)}
                              </span>
                            )} */}
                            </div>
                            <span
                              className="d-block"
                              size={"small"}
                              style={{ color: "red" }}
                            >
                              {cryptoAmountError && (
                                <Fragment>{cryptoAmountError}</Fragment>
                              )}
                            </span>
                          </div>

                          <div className="mb-4" style={{ flex: 1 }}>
                            <p className={Classes.label}>USD price</p>
                            <div className={Classes.outer}>
                              <input
                                placeholder="Amount"
                                type="number"
                                name="amountUsd"
                                onChange={(e) => {
                                  const value = Number(e.target.value);
                                  if (value < 0) {
                                    return;
                                  }
                                  handleChange(e);
                                  setPolygonPrice(
                                    e.target.value?.length>0?  toFixed(polyExchangeRate * value,5):polyExchangeRate * value
                                  );
                                  setUSDPrice(e.target.value);
                                }}
                                onBlur={handleBlur}
                                onFocus={(e) => e.preventDefault()}
                                // onInput={handleWheel}
                                onKeyDown={(e) => decimalnumbervalidator(e,true)}
                                onWheel={(event) => {
                                  event.preventDefault();
                                }}
                                value={usdPrice?.toString()}
                                style={{
                                  width: "100%",
                                  WebkitAppearance: "none",
                                  MozAppearance: "textfield",
                                }} // Disable spin buttons
                              />
                              <span>USD</span>
                            </div>
                            <span
                              className="d-block"
                              size={"small"}
                              style={{ color: "red" }}
                            >
                              {fiatAmountError && (
                                <Fragment>{fiatAmountError}</Fragment>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      {sellingMethodValue === 2 ? (
                        <div className={Classes.formInput}>
                          <p className={Classes.label}>Duration</p>
                          <RangePicker
                            showTime={{
                              format: 'HH:mm',
                              hideDisabledOptions: true,
                              defaultValue: [
                                moment().add(5, 'minutes').startOf('minute'),
                                moment().add(10, 'minutes').startOf('minute'),
                              ],
                            }}
                            format="YYYY-MM-DD HH:mm"
                            onChange={onChangeDate}
                            disabledDate={disabledDate}
                            disabledTime={(current, type) =>
                              type === 'start'
                                ? disabledStartTime(current)
                                : disabledEndTime(current)
                            }
                            onOk={onOk}
                          />
                          {datePickerError && datePickerError}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                    {auth?.role !== "organization" && (
                      <div className="mb-5">
                        <h3 className={Classes.metaTitle}>
                          Select nonprofits for donation* (minimum 1 nonprofit)
                        </h3>
                        <div className="mb-3">
                          <span
                            onClick={() => {
                              if (
                                selectedOranizations &&
                                selectedOranizations.length > 9
                              ) {
                                alertMessage(
                                  "error",
                                  "You can only add upto 10 nonprofits."
                                );
                              } else {
                                showModal();
                              }
                            }}
                            className={`${Classes.blueBtn} cursor_pointer`}
                          >
                            Add nonprofit
                          </span>

                          <div className={Classes.donation}>
                            {selectedOranizations &&
                              selectedOranizations?.length > 0 &&
                              selectedOranizations.map((organization, i) => (
                                <Row
                                  key={i}
                                  className={`${Classes.border_bottom} py-3`}
                                >
                                  <Col
                                    lg={17}
                                    md={10}
                                    className="d-flex align-items-center gap-2"
                                  >
                                    <p className={Classes.value}>Name:</p>
                                    <p className={Classes.value}>
                                      <b>{organization?.nonProfitName}</b>
                                    </p>
                                  </Col>
                                  <Col
                                    lg={6}
                                    md={10}
                                    className="d-flex align-items-center gap-2"
                                  >
                                    <p className={Classes.value}>Percentage:</p>
                                    <p className={Classes.value}>
                                      <b>{organization?.percentage}%</b>
                                    </p>
                                  </Col>
                                  <Col
                                    lg={1}
                                    md={4}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleDeleteOrganization(i)}
                                    className="d-flex justify-content-end"
                                  >
                                    <FaTrash color="red" />
                                  </Col>
                                </Row>
                              ))}
                          </div>
                        </div>
                      </div>
                    )}
                    <div>
                      <h3 className={Classes.metaTitle}>More options</h3>
                      <p className={Classes.summary}>Summary</p>
                      <div>
                        <div
                          className={`${Classes.border_bottom} d-flex align-items-center justify-content-between py-3`}
                        >
                          <p className={Classes.value}>Listing price</p>
                          <p className={Classes.value}>
                            {polygonPrice ||
                              0}{" "}
                            POL ($
                            {usdPrice ||0}
                            )
                          </p>
                        </div>
                        <div
                          className={`${Classes.border_bottom} d-flex align-items-center justify-content-between py-3`}
                        >
                          <p className={Classes.value}>Platform fee</p>
                          <p className={Classes.value}>{values?.userDefinedPlatformFee}%</p>
                        </div>
                        <div
                          className={`${Classes.border_bottom} d-flex align-items-center justify-content-between py-3`}
                        >
                          <p className={Classes.value}>Resale royalty</p>
                          <p className={Classes.value}>
                            {nft?.nft?.royaltyFee
                              ? nft?.nft?.royaltyFee / 100
                              : 0}
                            %
                          </p>
                        </div>
                        {/* <div
                          className={`${Classes.border_bottom} d-flex align-items-center justify-content-between py-3`}
                        >
                          <p className={Classes.value}>Donation fee</p>
                          <p className={Classes.value}>3.45%</p>
                        </div> */}
                        {/* <div
                          className={`d-flex align-items-center justify-content-between py-3`}
                        >
                          <p className={Classes.value}>
                            <b>Total potential earning</b>
                          </p>
                          <p className={Classes.value}>
                            <b>-- Poly</b>
                          </p>
                        </div> */}
                      </div>
                    </div>
                    <div className={Classes.footer}>
                      <Container>
                        <div className={Classes.btnOuter}>
                          <button
                            className={Classes.blueBtn}
                            disabled={!isValid || !dirty || isLoading || cryptoAmountError || polygonPrice <= 0}
                            type="submit"
                            style={{
                              cursor: !isValid || !dirty || isLoading || cryptoAmountError || polygonPrice <= 0 ? 'not-allowed' : 'pointer',
                              opacity: !isValid || !dirty || isLoading || cryptoAmountError || polygonPrice <= 0 ? 0.6 : 1,
                            }}
                          >
                            {isLoading ? (
                              <div className="d-flex justify-content-center align-items-center">
                                <ThreeDots
                                  height="30"
                                  width="30"
                                  radius="9"
                                  color="white"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              </div>
                            ) : (
                              "Complete listing"
                            )}
                          </button>
                        </div>
                      </Container>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
        title="Add Nonprofit"
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        className={Classes.orgModal}
      >
        <div className={Classes.inner}>
            <div className={Classes.formInput}>
              <p className={Classes.label}>Filter nonprofits by causes</p>
                <Select2
                placeholder="Select causes"
                style={{ marginTop: 10 }}
                onChange={handleCategoryChange}
                value={selectedOptions}
                options={categoriesOptions}
                isMulti
              />
            </div>
          <div className={Classes.formInput}>
            <p className={Classes.label}>Nonprofit name*</p>
            <Select
              placeholder="Select nonprofit"
              options={organizationOptions}
              onChange={handleOrganizationSelect}
              value={organizationOptions.find((opt) => opt.value === selected.value) || null}
            />
           
            
          </div>
          <div className={Classes.formInput}>
            <span>
              <span className={Classes.label}>
                Donation Percentage ({donationMin} to {donationMax?.toFixed(2)})*
              </span>
              {error && <span style={{ color: "red" }}> Invalid input</span>}
            </span>

            <input
              placeholder="e.g 5%"
              value={selected?.input}
              onKeyDown={(e) => decimalnumbervalidator(e, true, 2)}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                if (isNaN(value) || value < donationMin || value > donationMax?.toFixed(2)) {
                  setError(true);
                } else {
                  setError(false);
                }
                setSelected({ ...selected, input: e.target.value });
              }}
          
            />
          </div>
          <div className="d-flex justify-content-end">
            <button
              className={Classes.blueBtn}
              disabled={!selected?.value || !selected.input || error}
              onClick={() => handleChange()}
            >
              Add
            </button>
          </div>
        </div>
      </Modal>
      {showAlertMessage && <NotificationAlert title={errorMessageTitle} content={showAlertMessage}  visible={isAlertVisible}
       errorLogData={errLogData}
       isDebugMode={isDebugMode}
          onClose={() => {setIsAlertVisible(false)}}/>}
      {isInProgressAlertVisible && (
        <InProgressAlert
          title={sellingMethodValue ===1 ?'Listing in Progress':'Auctioning in Progress'}
          content='Please do not close this window or click the back button on your browser.'
          visible={isInProgressAlertVisible}
        />
      )}
      <CommonModal
        showModal={showDonationErrorModal}
        isModalOpen={isDonationErrorModalOpen}
        handleCancel={handleDonationErrorModalCancel}
        message={donationErrorMessage}
        modalType={modalType}
      />
    </div>
  );
};

export default ListingComp;
