import React from "react";
import Classes from "./tabs.module.scss";

export const OrganizationTabs = ({ show, setShow, hideFiscal }) => {
  return (
    <div
      className={`${Classes.tabsOuter} d-flex gap-lg-5 align-items-center mb-4`}
    >
      <div
        className={`${Classes.gap} d-flex align-items-center mb-4 me-lg-0 me-4`}
      >
        <button
          onClick={() => setShow("nftdrop")}
          className={
            show === "nftdrop" ? `${Classes.activeTab}` : `${Classes.tab}`
          }
        >
          NFT Drops
        </button>
        <button
          onClick={() => setShow("nftsBids")}
          className={
            show === "nftsBids" ? `${Classes.activeTab}` : `${Classes.tab}`
          }
        >
          NFT Bids
        </button>
        <button
          onClick={() => setShow("supporters")}
          className={
            show === "supporters" ? `${Classes.activeTab}` : `${Classes.tab}`
          }
        >
          Direct Supporters
        </button>
        <button
          onClick={() => setShow("inDirectsupporters")}
          className={
            show === "inDirectsupporters" ? `${Classes.activeTab}` : `${Classes.tab}`
          }
        >
          Indirect Supporters
        </button>
        <button
          onClick={() => setShow("collections")}
          className={
            show === "collections" ? `${Classes.activeTab}` : `${Classes.tab}`
          }
        >
          Collections
        </button>
        <button
          onClick={() => setShow("causes")}
          className={
            show === "causes" ? `${Classes.activeTab}` : `${Classes.tab}`
          }
        >
          Causes
        </button>
        <button
          onClick={() => setShow("favourites")}
          className={
            show === "favourites" ? `${Classes.activeTab}` : `${Classes.tab}`
          }
        >
          Favorites
        </button>
        {!hideFiscal && (
          <button
            onClick={() => setShow("fiscal")}
            className={
              show === "fiscal" ? `${Classes.activeTab}` : `${Classes.tab}`
            }
          >
            Fiscal Projects
          </button>
        )}
        <button
          onClick={() => setShow("verification")}
          className={
            show === "verification" ? `${Classes.activeTab}` : `${Classes.tab}`
          }
        >
          Verifications
        </button>
      </div>
      <div className={`${Classes.gap} d-flex align-items-center mb-4`}></div>
    </div>
  );
};
export const UsersTabs = ({ show, setShow, handleFriendsTabClick }) => {
  return (
    <div
      className={`${Classes.tabsOuter} d-flex gap-lg-5 align-items-center mb-4`}
    >
      <div
        className={`${Classes.gap} d-flex align-items-center mb-4 me-lg-0 me-4`}
      >
        <button
          onClick={() => setShow("nftdrop")}
          className={
            show === "nftdrop" ? `${Classes.activeTab}` : `${Classes.tab}`
          }
        >
          NFT Drops
        </button>
        <button
          onClick={() => setShow("nftsBids")}
          className={
            show === "nftsBids" ? `${Classes.activeTab}` : `${Classes.tab}`
          }
        >
          NFT Bids
        </button>
        <button
          onClick={() => setShow("collections")}
          className={
            show === "collections" ? `${Classes.activeTab}` : `${Classes.tab}`
          }
        >
          Collections
        </button>
        <button
          onClick={() => setShow("usercauses")}
          className={
            show === "usercauses" ? `${Classes.activeTab}` : `${Classes.tab}`
          }
        >
          Causes
        </button>
        <button
          onClick={() => setShow("favourites")}
          className={
            show === "favourites" ? `${Classes.activeTab}` : `${Classes.tab}`
          }
        >
          Favorites
        </button>
        <button
          onClick={() => setShow("charities")}
          className={
            show === "charities" ? `${Classes.activeTab}` : `${Classes.tab}`
          }
        >
          Charities
        </button>
      </div>
      <div className={`${Classes.gap} d-flex align-items-center mb-4`}>
        <button
          onClick={() => handleFriendsTabClick()}
          className={
            show === "friends" ? `${Classes.activeTab}` : `${Classes.tab}`
          }
        >
          Friends
        </button>
      </div>
    </div>
  );
};
