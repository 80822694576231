import React, { useEffect, useState } from 'react';
import Classes from './featuredcard.module.scss';
import { Col, Row } from 'antd';
import NFTImg from '../../assets/images/section/nftImg.svg';
import NFTsubImg1 from '../../assets/images/section/nftsubImg1.svg';
import NFTsubImg2 from '../../assets/images/section/nftsubImg2.svg';
import NFTsubImg3 from '../../assets/images/section/nftsubImg3.svg';
import UserImg from '../../assets/images/section/userImg.svg';
import Varified from '../../assets/images/icons/varified.svg';
import GreyStar from '../../assets/images/icons/greyStar.svg';
import { collectionsListing } from '../../services/collections.service';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { allCollectionsListing } from '../../services/collections.service';
const FeaturedCard = ({ data }) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const { collectionImage, collectionName, userId, _id } = data;
  const location = useLocation();
  const isExploreRoute = location.pathname === '/explore';
  return (
    // <div>
    <Col lg={6} md={6} sm={24} xs={24}>
      <div className={`${Classes.featuredCard} ${isExploreRoute ? Classes.explore : ''}`}>
        <Row
          className='d-flex justify-content-between mb-3'
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(`/collection-detail/${_id}`, { state: data })}
        >
          <Col md={24}>
            <img
              className={Classes.nftImg}
              src={collectionImage ? collectionImage : NFTImg}
              alt='img'
            />
          </Col>
          {/* <Col md={6}>
                    <div className='d-flex flex-column justify-content-between h-100'>
                      <img
                        className={Classes.subImg}
                        src={NFTsubImg1}
                        alt='img'
                      />
                      <img
                        className={Classes.subImg}
                        src={NFTsubImg2}
                        alt='img'
                      />
                      <img
                        className={Classes.subImg}
                        src={NFTsubImg3}
                        alt='img'
                      />
                    </div>
                  </Col> */}
        </Row>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center gap-3'>
            <div className='position-relative'>
              <img
                className={Classes.userImg}
                src={userId?.profilePicture ? userId?.profilePicture : UserImg}
                alt='img'
              />

              <img className={Classes.varified} src={Varified} alt='img' />
            </div>
            <div>
              <h3 className={Classes.title}>{collectionName}</h3>
              {/* <span className={Classes.desc}>Highest Bid 1/1</span> */}
            </div>
          </div>
          {/* <div className={Classes.rating}>
            <img src={GreyStar} alt='img' />
            <span>82</span>
          </div> */}
        </div>
      </div>
    </Col>
    // </div>
  );
};

export default FeaturedCard;
