import React from "react";
import Classes from "./featuredcard.module.scss";
import { Col, Row } from "antd";
import NFTImg from "../../assets/images/section/nftImg.svg";
import UserImg from "../../assets/images/section/userImg.svg";
import Varified from "../../assets/images/icons/varified.svg";
import GreyStar from "../../assets/images/icons/greyStar.svg";
import { useNavigate } from "react-router-dom";
import { PublicVideo } from "./PublicVideo";
import { FaVideo } from "react-icons/fa";
// import { VideoHome } from "../../helpers/VideoHome";

const PublicFeatureCard = ({ data }) => {
  const navigate = useNavigate();
  const {
    collectionImage,
    collectionName,
    userId,
    _id,
    name,
    file,
    primaryType,
  } = data;

  const handleClick = () => {
    if (collectionImage) {
      navigate(`/collection-detail/${_id}`, { state: data });
    } else {
      navigate(`/nft/${_id}`);
    }
  };

  return (
    <Col lg={6} md={6} sm={24} xs={24}>
      <div className={Classes.featuredCard}>
        <Row
          className="d-flex justify-content-between"
          style={{ cursor: "pointer" }}
          onClick={handleClick}
        >
          <Col md={24}>
            {collectionImage || primaryType === "image" ? (
              <img
                className={Classes.nftImg}
                src={collectionImage ? collectionImage : file}
                alt="img"
              />
            ) : (
              <div>
                <div className={`${Classes.videoContainer}`}>
                  <FaVideo />
                </div>
                <PublicVideo source={file} />
              </div>
            )}
          </Col>
        </Row>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <div className="position-relative">
              <img
                className={Classes.userImg}
                src={userId?.profilePicture ? userId?.profilePicture : UserImg}
                alt="img"
              />
              <img className={Classes.varified} src={Varified} alt="img" />
            </div>
            <div>
              <h3 className={Classes.title}>
                {collectionName ? collectionName : name}
              </h3>
            </div>
          </div>
          {/* <div className={Classes.rating}>
            <img src={GreyStar} alt='img' />
            <span>82</span>
          </div> */}
        </div>
      </div>
    </Col>
  );
};

export default PublicFeatureCard;
