import React, { useState,useEffect } from "react";
import Classes from "./settings.module.scss";
import { Container } from "react-bootstrap";
import User from "../../assets/images/icons/greyUser.svg";
import NotificationIcon from "../../assets/images/icons/greyNotification.svg";
import Profile from "../Profile";
import Password from "../Password";
import SponsorshipRequests from "../Sponsorships";
import Notification from "../Notification";
import { AiOutlineLock, AiOutlineInstagram } from "react-icons/ai";
import { GoSponsorTiers } from "react-icons/go";
import { FaRegEnvelopeOpen } from "react-icons/fa6";
import Invite from "../Invite";
import { useSelector } from "react-redux";
import SocialMedia from "../SocialMedia";
import { useNavigate } from "react-router-dom";
const SettingComp = () => {
  const [show, setShow] = useState("profile");
  const auth = useSelector((state) => state?.auth);
  const navigate=useNavigate()
  useEffect(() => {
    const {username,id } = auth||{};
    if (!username &&!id) {
      navigate("/login");
    }else if(!username &&id){
      navigate("/get-started");
    }
  }, [auth]);
  return (
    <div className={Classes.setting}>
      <Container>
        <div className={Classes.outer}>
          <div className={Classes.sidebar}>
            <p className={Classes.title}>Settings</p>
            <div className={Classes.tabsOuter}>
              <div
                onClick={() => setShow("profile")}
                className={
                  show === "profile"
                    ? `${Classes.navLink} ${Classes.navActive}`
                    : `${Classes.navLink}`
                }
              >
                <img src={User} alt="img" />
                <span>Profile</span>
              </div>
              <div
                onClick={() => setShow("password")}
                className={
                  show === "password"
                    ? `${Classes.navLink} ${Classes.navActive}`
                    : `${Classes.navLink}`
                }
              >
                <AiOutlineLock className={Classes.security} />
                <span>Password Settings</span>
              </div>
              <div
                onClick={() => setShow("socialMedia")}
                className={
                  show === "socialMedia"
                    ? `${Classes.navLink} ${Classes.navActive}`
                    : `${Classes.navLink}`
                }
              >
                <AiOutlineInstagram className={Classes.security} />
                <span>Social Media</span>
              </div>

              {/* {(auth?.role === "organization" &&
                auth?.organizationType ==="Registered 501(c)(3) nonprofit with IRS"
                  && (
                  <div
                    onClick={() => setShow("sponsorshipRequests")}
                    className={
                      show === "sponsorshipRequests"
                        ? `${Classes.navLink} ${Classes.navActive}`
                        : `${Classes.navLink}`
                    }
                  >
                    <GoSponsorTiers className={Classes.security} />
                    <span>Fiscal Projects</span>
                  </div>
                ))} */}
              {auth?.role === "user" && <div
                onClick={() => setShow("invite")}
                className={
                  show === "invite"
                    ? `${Classes.navLink} ${Classes.navActive} ${Classes.friendInvite}`
                    : `${Classes.navLink} ${Classes.friendInvite}`
                }
              >
                <FaRegEnvelopeOpen className={Classes.icon} />
                <span>Friend Invites</span>
              </div>}
              <div
                onClick={() => setShow("notification")}
                className={
                  show === "notification"
                    ? `${Classes.navLink} ${Classes.navActive}`
                    : `${Classes.navLink}`
                }
              >
                <img src={NotificationIcon} alt="img" />
                <span>Notifications</span>
              </div>
            </div>
          </div>
          <div className={Classes.main}>
            {show === "profile" && <Profile />}
            {show === "password" && <Password />}
            {show === "socialMedia" && <SocialMedia />}
            {auth?.role === "organization" && (
              <>{show === "sponsorshipRequests" && <SponsorshipRequests />}</>
            )}
            {show === "invite" && <Invite />}
            {show === "notification" && <Notification />}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SettingComp;
