import axios from 'axios';
export const completeTx = async (txHash,data) => {
    try {
      const auction = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/transaction/complete/${txHash}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authenticationToken')}`,
          },
        }
      );
      return auction;
    } catch (error) {
      return error.response.data;
    }
  };
  
  export const checkPendingBids = async () => {
    try {
      const bids = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/transaction/pending-bids`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authenticationToken')}`,
          },
        }
      );
      return bids;
    } catch (error) {
      return error.response.data;
    }
  };

  export const deleteFailedBidFromDb = async (data) => {
    try {
      const bids = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/transaction/delete-failed-bid`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authenticationToken')}`,
          },
        }
      );
      return bids;
    } catch (error) {
      return error.response.data;
    }
  };

  export const saveErrorLog = async (data) => {
    try {
      const error = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/errorLogs/add`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authenticationToken')}`,
          },
        }
      );
      return error;
    } catch (error) {
      return error?.response?.data;
    }
  };