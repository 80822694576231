import React from "react";
import { useNavigate } from "react-router-dom";
import Classes from "./nftCard.module.scss";
import polygon from "../../assets/images/icons/polygon.svg";
import { VideoHome } from "../../helpers/VideoHome";
import { FaVideo } from "react-icons/fa";

const NFTCard = ({ nftList }) => {
  const navigate = useNavigate();
  return (
    <div
      className={Classes.nftCard}
      onClick={() => navigate(`/nft/${nftList?._id}`)}
    >
      {nftList?.primaryType === "image" ? (
        <img
          src={nftList?.file}
          className={`${Classes.nftImg} mb-3`}
          alt="img"
        />
      ) : (
        <div>
          <div
            className={`${Classes.videoIcon}`}
          >
            <FaVideo />
          </div>
          <VideoHome source={nftList?.file} />
        </div>
        // <VideoComponent source={nftList?.file} />
      )}
      <div className="d-flex justify-content-between align-items-ceter mb-1">
        <p className={Classes.name}>{nftList?.name}</p>
        <img height={25} src={polygon} alt="icon" />
      </div>
      {/* {nftList?.auctionDetails && <p className={`${Classes.desc} mb-1`}>Highest Bid: {nftList?.auctionDetails?.highestBid} POL</p>} */}
      {nftList?.auctionDetails && (
        <>
          <div className="d-flex justify-content-between align-items-ceter mb-2">
            <p className={Classes.desc}>List Price</p>
            <p className={Classes.price}>
              {nftList?.auctionDetails?.sellingConfig?.startPrice?.amount} POL
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-ceter">
            <p className={Classes.desc}>Highest Bid</p>
            <p className={Classes.price}>
              {nftList?.auctionDetails?.highestBid} POL
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default NFTCard;
