import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../../layout/LandingLayout/Header';
import CreateNftComp from '../../CreateNftComp';
import { alertMessage } from '../../../constants/alertMessage';

const CreateNft = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth?.email) {
      navigate('/login');
      alertMessage('error', 'Authentication required.');
    }
  }, []);
  
  return (
    <div>
      <Header />
      {auth?.email && <CreateNftComp />}
    </div>
  );
};

export default CreateNft;
